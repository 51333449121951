import { Carousel, CarouselProps } from "@releaseit/ui";

export type CarouselRowProps = CarouselProps & {
  /** Heading to display above the carousel. */
  heading: string;
};

export function CarouselRow({ heading, ...props }: CarouselRowProps) {
  return (
    <div className="space-y-4">
      <h2 className="font-bold text-2xl">{heading}</h2>
      <Carousel
        {...props}
        className="
          [--slides-per-view:2.2] [--space-between:1rem]
          sm:[--slides-per-view:3]
          lg:[--slides-per-view:4] lg:[--space-between:1.5rem] 
        "
        classNames={{
          viewport: "overflow-hidden -mx-4 px-4 sm:px-3 sm:-mx-3 pt-1 pb-3",
          buttons: "lg:shadow-none",
          prevButton: "left-0 -translate-x-1/2 lg:-translate-x-[125%]",
          nextButton: "right-0 translate-x-1/2 lg:translate-x-[125%]",
        }}
      />
    </div>
  );
}
