import { ListingCardFragment, ListingType } from "@/generated/graphql";

import { type ListingCardProps } from "../components/card/ListingCard";
import { calculateStartingPrice } from "./calculate-starting-price";

export function mapListingToCard(listing: ListingCardFragment): ListingCardProps {
  return {
    type: listing.__typename === "InternalListing" ? ListingType.Internal : ListingType.External,
    id: listing.listingId,
    title: listing.title,
    imageURL: listing.images[0].thumbnailURL,
    location: listing.location.national
      ? "Australia-wide"
      : `${listing.location.locality} ${listing.location.state} ${listing.location.postcode}`,
    ownerAvatarURL: listing.owner.avatarURL,
    startingPrice: calculateStartingPrice(listing.pricing) / 100,
  };
}
