var FORMATTER = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});
var WITH_CENTS_FORMATTER = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});
export function formatCurrency(value) {
    var options = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
        withCents: false
    };
    var formatter = options.withCents ? WITH_CENTS_FORMATTER : FORMATTER;
    return formatter.format(value);
}
