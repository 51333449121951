import { useState } from "react";
import NextImage from "next/image";
import NextLink from "next/link";

import { cn } from "@releaseit/ui";
import { formatCurrency } from "@releaseit/utils-formatters";

import DollarCircleIcon from "@releaseit/assets/icons/dollar-circle.svg";
import ExternalIcon from "@releaseit/assets/icons/external.svg";

import { ListingType } from "@/generated/graphql";
import { analytics } from "@/utils/analytics";

export type ListingCardProps = React.ComponentPropsWithoutRef<"a"> & {
  /** The type of the listing. */
  type: ListingType;

  /** The id of the listing. */
  id: string;

  /** The title of the listing. */
  title: string;

  /** The listing cover image. */
  imageURL?: string | null;

  /** The owner's avatar iamge. */
  ownerAvatarURL?: string | null;

  /** The listings starting daily price in dollars. */
  startingPrice: number;

  /** The listing pickup location. */
  location: string;

  /** The listing appears above the fold and the image load should be prioritised */
  hasPriority?: boolean;
};

export function ListingCard({
  type,
  id,
  title,
  imageURL,
  ownerAvatarURL,
  startingPrice,
  location,
  className,
  hasPriority,
  ...props
}: ListingCardProps) {
  const [isLoading, setLoading] = useState(true);

  return (
    <NextLink href={`/listing/${id}`} prefetch={false}>
      <a
        {...props}
        {...(type === ListingType.External && {
          target: "_blank",
          rel: "noopener noreferrer",
          onClick(e) {
            analytics.providers.ga.event("Click", { event_category: "cpc", event_label: id });
            analytics.providers.fbp.event("cpcclick", { label: id });
            props.onClick?.(e);
          },
        })}
        className={cn(
          "flex flex-col overflow-hidden rounded-lg border border-gray-100 bg-white transition hover:-translate-y-1 hover:shadow-card",
          className
        )}
      >
        {/* Image */}
        <div className="aspect-w-1 aspect-h-1 relative">
          <NextImage
            src={imageURL || ""}
            alt={title}
            layout="fill"
            unoptimized
            priority={hasPriority}
            objectFit="cover"
            className={cn(
              "duration-500 ease-in-out",
              isLoading ? "scale-110 blur-2xl grayscale" : "scale-100 blur-0 grayscale-0"
            )}
            onLoadingComplete={() => setLoading(false)}
          />
        </div>

        {/* Bottom */}
        <div className="relative isolate flex grow flex-col p-3">
          {/* Owner Avatar */}
          <div
            className={cn(
              "absolute top-1 right-3 z-10 h-11 w-11 -translate-y-2/3 overflow-hidden border border-off-white",
              type === ListingType.Internal ? "rounded-full" : "rounded-lg"
            )}
          >
            <NextImage
              src={ownerAvatarURL || ""}
              alt="owner avatar"
              width={44}
              height={44}
              unoptimized
            />
          </div>

          {/* Community Banner */}
          {type === ListingType.Internal && (
            <p className="absolute inset-x-0 top-0 truncate bg-pastel-green px-3 py-2 text-xs font-medium tracking-wide text-mid-green">
              Releaseit Community
            </p>
          )}

          <p className="mt-6 grow truncate text-base font-medium">{title}</p>

          <div className="mt-1 flex gap-x-2 sm:mt-3">
            {/* Details */}
            <div className="flex grow flex-col overflow-hidden">
              <ListingPrice price={startingPrice} />
              <p className="truncate text-xs font-medium text-gray-400">{location}</p>
            </div>

            {/* Icon */}
            {type === ListingType.External && (
              <ExternalIcon className="h-5 w-6 shrink-0 self-end" />
            )}
          </div>
        </div>
      </a>
    </NextLink>
  );
}

function ListingPrice({ price }: { price: number }) {
  if (price <= 0) {
    return (
      <p className="flex items-center truncate text-sm font-medium text-mid-green">
        <DollarCircleIcon className="mr-0.5 h-5 w-5" /> Click for price
      </p>
    );
  }

  return (
    <p className="truncate text-sm">
      From <span className="text-lg font-medium text-mid-green">{formatCurrency(price)}</span> /day
    </p>
  );
}
